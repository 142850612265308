import Request from "src/request";
import { environment } from "src/variables";
// Components
import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
// Styles
import "src/app/styles/Signature.css"; // Optional: for styling

const asset: any = {
  customer: {
    id: 0,
    type: "",
    image: null,
  },
  request: {
    url: "",
    redirect: "",
  },
};

interface SignaturePadProps {
  onSave?: (signature: File) => void;
  width?: number;
  height?: number;
  fileName?: string;
  initialSignature?: string; // New prop for loading previous signature
}

const SignaturePad: React.FC<SignaturePadProps> = ({
  onSave,
  width = 400,
  height = 200,
  fileName = "signature.png",
  initialSignature,
}) => {
  const sigCanvas = useRef<SignatureCanvas>(null);
  const [isEmpty, setIsEmpty] = useState(true);

  // Load initial signature when component mounts or initialSignature changes
  useEffect(() => {
    if (initialSignature && sigCanvas.current) {
      sigCanvas.current.fromDataURL(initialSignature);
      setIsEmpty(false);
    }
  }, [initialSignature]);

  // Clear the signature
  const clearSignature = () => {
    sigCanvas.current?.clear();
    setIsEmpty(true);
  };

  // Convert data URL to Blob
  const dataURLtoBlob = (dataURL: string): Blob => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  // Save signature as a downloadable file
  const saveSignatureAsDownload = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const dataURL = sigCanvas.current.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Save signature as a File object and pass to callback
  const saveSignatureAsFile = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const dataURL = sigCanvas.current.toDataURL("image/png");
      const blob = dataURLtoBlob(dataURL);
      const signatureFile = new File([blob], fileName, { type: "image/png" });
      onSave?.(signatureFile);
    }
  };

  const handleBegin = () => {
    setIsEmpty(false);
  };

  return (
    <div className="signature-container">
      <SignatureCanvas
        ref={sigCanvas}
        canvasProps={{
          width,
          height,
          className: "signature-canvas",
        }}
        onBegin={handleBegin}
      />
      <div className="signature-controls">
        <button onClick={clearSignature} disabled={isEmpty}>
          Clear
        </button>
        <button onClick={saveSignatureAsDownload} disabled={isEmpty}>
          Download
        </button>
        <button onClick={saveSignatureAsFile} disabled={isEmpty}>
          Save
        </button>
      </div>
    </div>
  );
};

export default function Signature(props: any) {
  asset.request.url = props.urlRequest;
  asset.customer.id = props.id;
  asset.customer.type = props.type;
  const [lastSignature, setLastSignature] = useState<string | null>(props.image);

  const clearSignature = () => setLastSignature(null);

  const handleSignatureSave = (signatureFile: File) => {
    console.log("Signature saved:", signatureFile);
    // You can save the signature data URL or convert it to another format
    const formData = new FormData();
    formData.append("image", signatureFile);
    formData.append("customer_id", asset.customer.id);
    formData.append("type", asset.customer.type);

    const url = environment.apiPath + asset.request.url;
    Request(url, { method: "POST", body: formData }).subscribe(
      (result: any) => {},
      (error: any) => {}
    );
  };

  return (
    <div>
      {!lastSignature ? (
        <SignaturePad onSave={handleSignatureSave} width={props.width} height={props.height} />
      ) : (
        <div className="signature-container">
          <h2>Last Signature Preview:</h2>
          <img src={lastSignature} alt="Last Signature" style={{ border: "1px solid #ccc" }} />
          <div className="signature-controls">
            <button onClick={clearSignature}>Clear</button>
          </div>
        </div>
      )}
    </div>
  );
}
