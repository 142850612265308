import Request from "src/request";
import { environment } from "src/variables";
// Components
import React, { ChangeEvent, useState } from "react";
import { Typography } from "@material-ui/core";
// Styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Props interface
interface FileProps {
  onFileSelect?: (file: File) => void;
  accept?: string; // e.g., 'image/*,video/*'
  multiple?: boolean;
  allowCamera?: boolean; // New prop to enable camera capture
}

const asset: any = {
  customer: {
    id: 0,
    type: "",
    image: null,
  },
  request: {
    url: "",
    redirect: "",
  },
};

const FileUpload: React.FC<FileProps> = ({
  onFileSelect,
  accept = "image/*", // Default to images for camera
  multiple = false,
  allowCamera = false,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading]: any = useState(false);
  const [success, setSuccess]: any = useState(false);
  const [errors, setErrors]: any = useState(asset.errors);
  const [previewUrl, setPreviewUrl] = useState<string | null>(asset.customer.image);

  // Handle file selection (from file system or camera)
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const filesArray = Array.from(files);
      setSelectedFiles(filesArray);

      // Generate preview for images
      if (filesArray[0]?.type.startsWith("image/")) {
        const url = URL.createObjectURL(filesArray[0]);
        setPreviewUrl(url);
        handleFileSelect(filesArray[0]);
      }

      // Call callback if provided
      if (onFileSelect && filesArray.length > 0) {
        onFileSelect(filesArray[0]);
      }
    }
  };

  const handleFileSelect = async (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("customer_id", asset.customer.id);
    formData.append("type", asset.customer.type);

    const url = environment.apiPath + asset.request.url;
    Request(url, { method: "POST", body: formData }).subscribe(
      (result: any) => {
        setLoading(false);
      },
      (error: any) => {
        setErrors(error.message);
        setLoading(false);
      }
    );
  };

  // Handle drag and drop (optional, not typically used with camera)
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      const filesArray = Array.from(files);
      setSelectedFiles(filesArray);
      if (onFileSelect && filesArray.length > 0) {
        onFileSelect(filesArray[0]);
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      imageContainer: {
        minWidth: "100%",
      },
      spinnerContainer: {
        position: "absolute",
        index: "1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      image: {
        width: "68%",
        maxWidth: "520px",
        height: "32vw",
        maxHeight: "270px",
        display: "flex",
        justifyContent: "center",
      },
      success: {
        color: "#4caf50",
        position: "relative",
        top: 30,
      },
    })
  );

  const classes = useStyles();

  return (
    <div onDrop={handleDrop} onDragOver={handleDragOver} className={classes.imageContainer}>
      {success && <Typography className={classes.success}>{success}</Typography>}
      <input
        type="file"
        id="fileInput"
        accept={accept}
        multiple={multiple}
        capture={allowCamera ? "environment" : undefined} // 'environment' for rear camera, 'user' for front camera
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <label htmlFor="fileInput">
        {asset.customer.image ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {previewUrl && <img className={classes.image} src={previewUrl} alt="Preview" />}
          </div>
        ) : (
          <p>{allowCamera ? "Take a photo or select a file" : "Drag and drop files here or click to select"}</p>
        )}
      </label>
    </div>
  );
};

export default function File(props: any) {
  asset.request.url = props.urlRequest;
  asset.customer.id = props.id;
  asset.customer.type = props.type;
  asset.customer.image = props.image;

  return (
    <div>
      <FileUpload
        accept="image/*" // Restrict to images for camera use
        multiple={false}
        allowCamera={true}
      />
    </div>
  );
}
