import Request from "src/request";
import { environment } from "src/variables";
import { ThemeProvider, createTheme, Typography } from "@material-ui/core";

// Components
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "src/app/components/Header";
import File from "src/app/components/File";
import Signature from "src/app/components/Signature";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Styles
import { Wrapper, Spacer } from "src/app/App.styles";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const asset: any = {
  customer: null,
  errors: {},
  request: {
    url: "",
    token: "",
  },
};

const toastAll: any = toast;

const theme = createTheme({
  palette: {
    primary: {
      main: "#1ba0d7",
    },
    secondary: {
      main: "#a27c10",
    },
  },
});

const bannerText: any = {
  style: {
    fontFamily: "Georgia, serif",
    background: "linear-gradient(#1ba0d7, #a27c10)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "50px",
  },
};

export default function Dispatch() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [customer, setCustomer] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = environment.apiPath + "/public/courier/dispatch?token=" + searchParams.get("token");
    Request(url, { method: "GET" }).subscribe(
      (result: any) => {
        const customerData: any = new Array(result);
        setCustomer(customerData);
        setLoading(false);
      },
      (error: any) => {
        console.error("Error fetching data:", error.message);
        setLoading(false); // Handle error state if needed
      }
    );
  }, []);

  const handleDelivery = (id: number) => {
    if (!id) return;
    const customerData: any = customer[0];
    const payload: any = { delivery_id: id, invoice_id: customerData.invoice.id };
    const url = environment.apiPath + "/public/courier/update";
    Request(url, { method: "PATCH", body: JSON.stringify(payload) }, "application/json").subscribe(
      (result: any) => {},
      (error: any) => {
        console.error("Error fetching data:", error.message);
      }
    );
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dispatchContainer: {
        margin: "0 20px",
      },
      foreground: {
        minWidth: "100%",
        minHeight: "100%",
      },
      toast: {
        top: "50%!important",
        left: "50%!important",
        transform: "translate(-50%, -50%)!important",
        position: "fixed",
      },
      delivery: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      deliveryButton: {
        border: "none",
        borderRadius: "4px",
        color: "white",
        padding: "8px 16px",
        margin: "4px 2px",
        backgroundColor: "#4CAF50",
      },
    })
  );
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <section className={classes.foreground}>
        <Wrapper>
          <Header text={environment.siteTitle} style={bannerText.style} divider={true} />
        </Wrapper>
        <Spacer height={32} />
        <div className={classes.dispatchContainer}>
          {customer &&
            customer.map((item: any) => {
              if (loading) {
                return <div>Loading...</div>;
              } else {
                return customer ? (
                  <div>
                    <Typography variant="h6" component="div">
                      Customer: {item.first_name + " " + item.last_name}
                    </Typography>
                    <Typography variant="h6" component="div">
                      Phone#: <a href={"tel:+1" + item.phone}>{item.phone}</a>
                    </Typography>
                    <Typography variant="h6" component="div">
                      Email: <a href={"mailto:" + item.email}>{item.email}</a>
                    </Typography>
                    <File
                      id={item.id}
                      type="id"
                      image={item.image && item.image.id ? item.image.id : null}
                      urlRequest="/public/courier/upload-image"
                    />
                    <p>{item.image && item.image.id ? "Click image to update." : "Click above to upload a image."}</p>
                    <Signature
                      id={item.id}
                      type="signature"
                      image={item.image && item.image.signature ? item.image.signature : null}
                      urlRequest="/public/courier/upload-image"
                      width="400"
                      height="200"
                    />
                    {item.invoice ? (
                      <div>
                        <Spacer height={32} />
                        <Typography variant="h6" component="div">
                          Invoice #{item.invoice.number}
                          <br />
                          Please complete the following pickups then the drop-offs.
                        </Typography>
                        {item.invoice.deliveries.map((delivery: any) => {
                          return (
                            <span className={classes.delivery}>
                              {delivery.drop === 0 ? "Pickup address: " : "Drop off address: "}
                              <a
                                href={
                                  "https://google.com/maps?q=" +
                                  encodeURI(
                                    delivery.address_1 + "," + delivery.city + "," + delivery.state + "," + delivery.zip
                                  )
                                }
                              >
                                {delivery.address_1 + " " + delivery.city + ", " + delivery.state + " " + delivery.zip}
                              </a>
                              <button
                                className={classes.deliveryButton}
                                onClick={() => handleDelivery(item.keyedDeliveries[delivery.id].id)}
                              >
                                Done
                              </button>
                            </span>
                          );
                        })}
                        <Typography variant="h6" component="div" className={classes.delivery}>
                          <span>Total:</span>
                          <span>${item.invoice.total.toFixed(2)}</span>
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                ) : null;
              }
            })}
        </div>
        <ToastContainer className={classes.toast} />
      </section>
    </ThemeProvider>
  );
}
