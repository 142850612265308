import { ThemeProvider, createTheme } from "@material-ui/core";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Dispatch from "./pages/Dispatch";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1ba0d7",
    },
    secondary: {
      main: "#a27c10",
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dispatch" element={<Dispatch />} />
      </Routes>
    </ThemeProvider>
  );
}
